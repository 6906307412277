import React from 'react'
import Organisationcard from './Organisationcard'

export default function Overview() {
  return (
    <div>
        <Organisationcard />
    </div>
  )
}

import {React, useEffect,useState} from 'react';
import { Button } from 'reactstrap';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Axios from 'axios';
import io from 'socket.io-client';
import * as Icon from 'react-feather';
import { auth } from '../../../firebase';
import './minutes.css'


const socket = io.connect("https://datagapi.uuidesign.xyz:3001")

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
export default function Allminutes() {
  const [allMinutes,setAllMinutes] = useState([])
  const [email,setEmail] = useState('');
  const [company,setCompany] = useState('');
  const [minutesTitle, setMinutesTitle] = useState('');
  const [meetingStart,setMeetingstart] = useState('');
  const [meetingClose, setMeetingclose] = useState('');
  const [precidingOfficer,setPrecidingOfficer] = useState('');
  const [quoram,setQuoram] = useState("");
  const [approvedBy,setApprovedBy] = useState('');
  const [secondedBy,setSecondedBy] = useState('')
  const [minutesDate,setMinutesDate] = useState("")
  const [membersPresent,setMembersPresent] = useState([]);
  const [membersAbsent,setMembersAbsent] = useState([]);
  const [agendas,setAgendas] = useState([]);
  const [aob,setAob] = useState([]);
  const [motions,setMotions] = useState([]);
  const [drafts,setDrafts] = useState([]);
  const [companyData,setCompanyData] = useState({});
  const [meetingType,setMeetingType] = useState("")
  // const [minutesID,setMinutesID] = useState('');

  useEffect(() =>{
    auth.onAuthStateChanged((user) =>{
      if(user){
          
         setEmail(user.email)

         socket.emit('getCompany',{email:user.email});
         
         socket.on('companyData', (data) => {
          setCompany(data.company);
          
        });
          
      }
     
    })
  },[])

  useEffect(() => {
    const min = 1000000; // Minimum value (inclusive)
    const max = 9999999; // Maximum value (inclusive)
    const ran = Math.floor(Math.random() * (max - min + 1)) + min;

    const randomNum = ran * Date.now().toString();

    const status = 'Closed';
    const status2 = 'Ongoig'
    const company2 = company;
    socket.emit('getAllMinutes',{
      company2,status,randomNum
    });
    socket.on('allMinutes', (data13) => {
      setAllMinutes(data13);
    });

    //Ongoing Minutess
    socket.emit('getOngoingMinutes',{
      company2,status2,randomNum
    });
    socket.on('ongoingMinutes', (data14) => {
      setDrafts(data14);
    });

  }, [socket,company]);


  useEffect(() =>{
       
    Axios.get(`https://datagapi.uuidesign.xyz:3001/getMinute?company=${company}&minuteTitle=${minutesTitle}`).then((res)=>{
        
          setMeetingstart(res.data.minutesTime)
          setMeetingclose(res.data.minutesClose)
          setMinutesDate(res.data.minutesDate)
          setPrecidingOfficer(res.data.precidingOfficer)
          setQuoram(res.data.quoram)
          setApprovedBy(res.data.approvedBy)
          setSecondedBy(res.data.secondedBy)
          setMeetingType(res.data.meetingType)

        })
   
  },[minutesTitle])

  useEffect(() =>{
       
    Axios.get(`https://datagapi.uuidesign.xyz:3001/getMembersPresent?company=${company}&minuteTitle=${minutesTitle}`).then((res)=>{
        
          setMembersPresent(res.data)

        })
   
  },[minutesTitle])

  useEffect(() =>{
       
    Axios.get(`https://datagapi.uuidesign.xyz:3001/getMotions?company=${company}&minuteTitle=${minutesTitle}`).then((res)=>{
        
          setMotions(res.data)

        })
   
  },[minutesTitle])

  useEffect(() =>{
       
    Axios.get(`https://datagapi.uuidesign.xyz:3001/getMembersAbsent?company=${company}&minuteTitle=${minutesTitle}`).then((res)=>{
        
          setMembersAbsent(res.data)

        })
   
  },[minutesTitle])
  useEffect(() =>{
       
    Axios.get(`https://datagapi.uuidesign.xyz:3001/getAgendas?company=${company}&minuteTitle=${minutesTitle}`).then((res)=>{
        
          setAgendas(res.data)

        })
   
  },[minutesTitle])

  useEffect(() =>{
       
    Axios.get(`https://datagapi.uuidesign.xyz:3001/getAOB?company=${company}&minuteTitle=${minutesTitle}`).then((res)=>{
        
          setAob(res.data)

        })
   
  },[minutesTitle])

  // useEffect(() =>{
  //   Axios.get(`https://datagapi.uuidesign.xyz:3001/getOngoingMinutes?company=${company}`)
  //           .then(response => {
  //             setDrafts(response.data);
  //           })
  // },[company])

  

  const getMinutes = (event) =>{
    setMinutesTitle(event.target.value)
  }
  const deleteMinutes = (event) =>{ 
    const min = 1000000; // Minimum value (inclusive)
    const max = 9999999; // Maximum value (inclusive)
    const ran = Math.floor(Math.random() * (max - min + 1)) + min;

    const randomNum = ran * Date.now().toString();
    const minutesID = event.target.value;
    socket.emit("deleteMinute",{
      minutesID
    });

    socket.on('minuteDeleted', response => {
    const status = 'Closed';
    const status2 = 'Ongoig'
    const company2 = company;
    
    socket.emit('getAllMinutes',{
      company2,status,randomNum
    });

    socket.on('allMinutes', (data15) => {
      setAllMinutes(data15);
    });

    //Ongoing Minutess
    socket.emit('getOngoingMinutes',{
      company2,status2,randomNum
    });
    socket.on('ongoingMinutes', (data16) => {
      setDrafts(data16);
    });
    });
   
  }


  const realDate = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(meetingStart)
  const closeTime = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(meetingClose)
  let previousMotionTitle = null;

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.section}>
      
      <div className='minutesContent'>
      <Text style={{fontSize:'20px',marginLeft:'30%'}}>{company}</Text>
      <Text style={{fontSize:'16px',marginLeft:'34%',marginTop:'1%'}}>{meetingType}</Text>
                                  <Text style={{fontSize:'12px',marginLeft:'25%',marginTop:'3%'}}>Minutes of meeting held on {minutesDate } in (location)</Text>
                                  
                                  <Text style={{fontSize:'11px',marginTop:'3%'}}>Members Present</Text>
                                  
                                  {membersPresent.map((val) =>{
                                    return(
                                      <>
                                      <Text style={{fontSize:'10px',marginTop:'1%'}}>{val.member} - {val.position}</Text>
                                      </>
                                    )
                                  })}
                                  <br />
                                  <Text style={{fontSize:'11px',marginTop:'3%'}}>Members Absent</Text>
                                  <p>_________________</p>
                                  {membersAbsent.map((val) =>{
                                    return(
                                      <>
                                      <Text style={{fontSize:'10px',marginTop:'1%'}}>{val.member} - {val.position}</Text>
                                      </>
                                    )
                                  })}

                                  <br />
                                  <Text style={{fontSize:'10px',marginTop:'3%'}}>A Quoram was established. The meeting was called to order at {realDate} by {precidingOfficer} </Text>
                                  
                                  <Text style={{fontSize:'11px',marginTop:'3%'}}>Order of Business - Old Business</Text>
                                  <br />
                                  <Text style={{fontSize:'11px',marginTop:'1%'}}>Approval of Previous Minutes</Text>
                                  <Text style={{fontSize:'10px',marginTop:'1%'}}>The minutes from the previous meeting were read. They were approved by {approvedBy} and secondend by {secondedBy}</Text>
                                  <Text style={{fontSize:'11px',marginTop:'1%'}}>Matters Arising</Text>
                                  <Text style={{fontSize:'10px',marginTop:'1%'}}>N/A</Text>
                                  <br />
                                  <Text style={{fontSize:'11px',marginTop:'1%'}}>Order of Business - New Business</Text>
                                  {agendas.map((val,index) =>{
                                    return(
                                      <>
                                      <Text style={{fontSize:'11px',marginTop:'2%'}}>{index+1}. {val.agendaTitle}</Text>
                                      <Text style={{fontSize:'10px',marginTop:'1%'}}>{val.agendaDesc}</Text>
                                      <Text style={{fontSize:'11px',marginTop:'1%'}}>Action:{val.actionTask}</Text>
                                      <Text style={{fontSize:'10px',marginTop:'1%'}}>Assigned to: {val.actionTo}</Text>
                                      <Text style={{fontSize:'10px',marginTop:'1%'}}>Status: {val.status}</Text>
                                      </>
                                    )
                                  })}

                                   <Text style={{fontSize:'11px',marginTop:'3%'}}>Motions Passed</Text>
                                   
                                  {motions.map((val,index) =>{
                                    if (val.motionTitle !== previousMotionTitle) {
                                      // Update the previous motion title to the current one
                                      previousMotionTitle = val.motionTitle;
                                      // Render the motion title
                                      return (
                                        <>
                                          <Text  style={{ fontSize: '11px', marginTop: '1%' }}>
                                            {val.motionTitle} 
                                          </Text>
                                          <Text  style={{ fontSize: '10px', marginTop: '1%' }}>
                                            {val.forMotion} - YES
                                          </Text>
                                        </>
                                      );
                                    } 
                                      // Only render the motion content without the title if it's not the first occurrence
                                      return (
                                        <Text  style={{ fontSize: '10px', marginTop: '1%' }}>
                                          {val.forMotion} - YES
                                        </Text>
                                        
                                      );
                                    
                                  })}


                                    {motions.map((val,index) =>{
                            
                                      return (
                                        <Text  style={{ fontSize: '10px', marginTop: '1%' }}>
                                          {val.againstMotion} -NO
                                        </Text>
                                        
                                      );
                                    
                                  })}
                                  
                                  <Text style={{fontSize:'11px',marginTop:'3%'}}>Any Other Business</Text>
                                  {aob.map((val,index) =>{
                                    return(
                                      <>
                                      <Text style={{fontSize:'10px',marginTop:'2%'}}>{index+1}. {val.aobTitle}</Text>
                                      <Text style={{fontSize:'10px',marginTop:'1%'}}>{val.aobDesc}</Text>

                                      </>
                                    )
                                  })}

                                  <Text style={{fontSize:'11px',marginTop:'1%'}}>Next Meeting</Text>
                                  <Text style={{fontSize:'10px',marginTop:'1%'}}>(Place) and  (time)</Text>
                                  <Text style={{fontSize:'10px',marginTop:'1%'}}>Motion to adjourn was made at {closeTime} and was passed unanimously</Text>
                                </div>
                                </View>
      </Page>
    </Document>
  );
  
  return (
    <div className='minutesWrapper'>
      <div className="minutesLeft">
        <h4>All Minutes({allMinutes.length})</h4>
       
        <hr />
        {allMinutes.map((val) =>{
          const date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)
          const date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.postTime)
          return(
            <>
            <button className='minutesButton' onClick={getMinutes} type='button' value={val.minutesTitle}>{val.minutesTitle} <Icon.ArrowRightCircle size='14px' /></button>
          
            </>
          )
        })}

       

      </div>
      <div className="minutesRight">
        <div className="minutesHeader">
          <h4 style={{marginLeft:'3%'}}><strong>{minutesTitle}</strong></h4>
          

        </div>
        
        

        {
                (() => {
                    if(minutesTitle==='') {
                            return (
                                <>
                                <p>Select minutes to preview</p>
                                </>
                            )
                        }
                            return (
                              <PDFViewer style={{ width: '100%', height: '100vh' }}>
                                <MyDocument />
                            </PDFViewer>
                            )
                        
                })()  
            }  


      </div>

    </div>
  )
}

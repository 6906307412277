// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import {getDatabase} from "firebase/database";

// import "firebase/auth";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA467_tgV01nvs_xfQLZ6BjqcRDTwiWsKo",
    authDomain: "data-g-90340.firebaseapp.com",
    projectId: "data-g-90340",
    storageBucket: "data-g-90340.appspot.com",
    messagingSenderId: "594564870567",
    appId: "1:594564870567:web:9245854be9f323e025c682",
    measurementId: "G-G6M5SC7D76"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app)
export const db = getDatabase(app);
